import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  IconButton,
  Switch,
  Toolbar,
  Tooltip,
} from '@mui/material';
import {
  FiBell,
  FiMenu,
  FiUser,
  FiMessageSquare,
} from 'react-icons/fi';
import { MdOutlineBugReport } from 'react-icons/md';
import {
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSession } from 'next-auth/react';
import LanguagePopover from './LanguagePopover';
import AccountPopover from './account/AccountPopover';
import NavbarTitle from './NavbarTitle';

const languages = {
  en: '/static/icons/uk_flag.svg',
  de: '/static/icons/de_flag.svg',
};

const MainNavbarRoot = styled(AppBar)(({ theme }) => ({
  gridArea: '1 / 1 / 2 / 2',
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3],
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none',
    }),
  [theme.breakpoints.up('lg')]: {
    gridArea: '1 / 2 / 2 / 3',
  },
}));

function LanguageButton() {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpenPopover}
        sx={{ ml: 1 }}
      >
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
            '& img': {
              width: '100%',
            },
          }}
        >
          <img
            alt=""
            src={languages[i18n.language]}
          />
        </Box>
      </IconButton>
      <LanguagePopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
}

function DebugButton() {
  const [update, setUpdate] = useState(false);

  if (process.env.NEXT_PUBLIC_DEBUG !== 'true') {
    return null;
  }

  const debug = window.sessionStorage.getItem('debug') === 'true';

  const handleChange = () => {
    window.sessionStorage.setItem('debug', debug ? 'false' : 'true');
    setUpdate(!update);
  };

  return (
    <Tooltip title="Enable/disable debug mode">
      <Box alignItems="center" display="flex">
        <Switch
          checked={debug}
          onChange={handleChange}
          size="small"
        />
        <IconButton onClick={handleChange}>
          <MdOutlineBugReport />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

function NotificationsButton() {
  const anchorRef = useRef(null);
  const [unread, setUnread] = useState(0);
  const [openPopover, setOpenPopover] = useState(false);
  // Unread notifications should come from a context and be shared with both this component and
  // notifications popover. To simplify the demo, we get it from the popover

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleUpdateUnread = (value) => {
    setUnread(value);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          ref={anchorRef}
          onClick={handleOpenPopover}
          sx={{ ml: 1 }}
        >
          <Badge
            badgeContent={unread}
            color="error"
          >
            <FiBell />
          </Badge>
        </IconButton>
      </Tooltip>
      {/*
      <NotificationsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        onUpdateUnread={handleUpdateUnread}
        open={openPopover}
      />
      */}
    </>
  );
}

function AccountButton() {
  const { data: session } = useSession();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const { user } = session ?? {};

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        ref={anchorRef}
        component={ButtonBase}
        onClick={handleOpenPopover}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2,
        }}
      >
        <Avatar
          src={user.profileImage?.formats?.small?.url ?? user.profileImage?.url}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          <FiUser />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
}

export default function MainNavbar(props) {
  const {
    onOpenMainSidebar,
    onOpenSecondarySidebar,
    ...other
  } = props;

  return (
    <MainNavbarRoot
      position="static"
      {...other}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onOpenMainSidebar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <FiMenu />
        </IconButton>
        <NavbarTitle />
        <DebugButton />
        <LanguageButton />
        <NotificationsButton />
        <AccountButton />
        <IconButton
          onClick={onOpenSecondarySidebar}
          sx={{
            ml: 2,
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <FiMessageSquare />
        </IconButton>
      </Toolbar>
    </MainNavbarRoot>
  );
}

MainNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

MainNavbar.defaultProps = {
  onOpenSidebar: null,
};
