import {
  Box,
  Drawer,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { selectCurrentTenant } from '@slices/tenant';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
} from 'react-icons/tb';
import Scrollbar from './Scrollbar';
import MainSidebarSection from './MainSidebarSection';
import Logo from './Logo';
import AuthAclGuard from './AuthAclGuard';
import { EXPANDED_MAIN_SIDEBAR_WIDTH } from '../theme';

const MainSidebarRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    gridArea: '1 / 1 / 3 / 2',
  },
}));

export default function MainSidebar(props) {
  const {
    isCollapsed,
    isCollapsible,
    onClose,
    onCollapse,
    onExpand,
    open,
    sections,
  } = props;
  const tenant = useSelector(selectCurrentTenant);
  const router = useRouter();

  const handlePathChange = useCallback(() => {
    if (!router.isReady) {
      return;
    }

    onClose?.();
  }, [router, onClose]);

  const handleToggleSidebarCollapse = useCallback(() => {
    if (!isCollapsible) {
      if (open) {
        onClose?.();
      }

      return;
    }

    if (isCollapsed) {
      onExpand?.();
    } else {
      onCollapse?.();
    }
  }, [
    isCollapsed,
    isCollapsible,
    onClose,
    onCollapse,
    onExpand,
    open,
  ]);

  useEffect(handlePathChange, [
    router.isReady,
    router.asPath,
    handlePathChange,
  ]);

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
        '& .simplebar-scrollbar::before': {
          bgcolor: (theme) => (theme.palette.sidebar?.scrollbar?.main ?? 'background.paper'),
        },
      }}
    >
      <MainSidebarRoot>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-start"
          sx={{
            pl: isCollapsed ? 2 : 3,
            pr: 1,
            py: 1,
            mb: 1,
            height: 64,
            flex: '0 0 64px',
            bgcolor: 'background.leftLogo',
            boxShadow: (theme) => theme.shadows[3],
          }}
        >
          {!isCollapsed && (
            <NextLink
              href="/"
              legacyBehavior
              passHref
            >
              <a style={{ display: 'block', marginRight: 'auto' }}>
                <Logo
                  height={42}
                  sx={{ display: 'block' }}
                  variant="dark"
                  width={121}
                />
              </a>
            </NextLink>
          )}
          <Tooltip
            placement="right"
            title="Einklappen"
          >
            <IconButton
              onClick={handleToggleSidebarCollapse}
              sx={{
                color: (theme) => theme.palette.sidebar?.link?.default?.main ?? 'neutral.300',
              }}
            >
              {isCollapsed ? (
                <TbLayoutSidebarLeftExpand />
              ) : (
                <TbLayoutSidebarLeftCollapse />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ flexGrow: 1, pb: 5 }}>
          {sections.map((section) => (
            <AuthAclGuard
              key={section.title}
              requiredAcl={section.acl ?? []}
            >
              <MainSidebarSection
                isCollapsed={isCollapsed}
                path={router.asPath}
                sx={{
                  mt: 1,
                  '& + &': {
                    mt: 1.5,
                  },
                }}
                tenant={tenant}
                {...section}
              />
            </AuthAclGuard>
          ))}
        </Box>
      </MainSidebarRoot>
    </Scrollbar>
  );

  if (isCollapsible) {
    return (
      <Paper
        square
        sx={{
          gridArea: '1 / 1 / 3 / 2',
          backgroundColor: 'background.leftSidebar',
          borderRightColor: 'divider',
          borderRightStyle: 'solid',
          borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
          color: '#FFFFFF',
        }}
      >
        {content}
      </Paper>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.leftSidebar',
          color: '#FFFFFF',
          width: EXPANDED_MAIN_SIDEBAR_WIDTH,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

MainSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array,
};

MainSidebar.defaultProps = {
  onClose: null,
  open: false,
  sections: [],
};
