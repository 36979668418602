import {
  Box,
  Divider,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useSnackbar } from 'notistack';
import MessageList from './MessageList';
import MessageTextBox from './MessageTextBox';

export default function Chat() {
  const [threadId, setThreadId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { data: initialMessagesData } = useQuery(FETCH_INITIAL_MESSAGES);
  const [fetchThreadMessages, { data }] = useLazyQuery(FETCH_THREAD_MESSAGE, {
    pollInterval: 1000,
  });
  const [createThread] = useMutation(CREATE_THREAD);
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE);
  const messages = data?.messages.data
    ?? initialMessagesData?.assistantInitialMessages.data
    ?? [];

  useEffect(() => {
    const existingThreadId = initialMessagesData?.assistantExistingThread.data?.id;

    if (existingThreadId) {
      setThreadId(existingThreadId);
      fetchThreadMessages({ variables: { threadId: existingThreadId } });
    }
  }, [
    setThreadId,
    initialMessagesData,
    fetchThreadMessages,
  ]);

  const handleSubmitMessage = useCallback(async (value) => {
    try {
      let localThreadId = threadId;

      if (!localThreadId) {
        // Create thread first & then post the message
        const { data: threadData2 } = await createThread();
        localThreadId = threadData2.assistantCreateThread.data.id;
        setThreadId(localThreadId);
      }

      await createMessage({
        variables: {
          threadId: localThreadId,
          data: {
            content: value,
          },
        },
      });
      fetchThreadMessages({ variables: { threadId: localThreadId } });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [
    threadId,
    createMessage,
    createThread,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      sx={{
        overflowY: 'hidden',
      }}
    >
      <MessageList
        items={messages}
        loading={loading}
      />
      <Box>
        <Divider />
        <Box p={1}>
          <MessageTextBox
            disabled={loading}
            onSubmit={handleSubmitMessage}
          />
        </Box>
      </Box>
    </Box>
  );
}

const FETCH_INITIAL_MESSAGES = gql`
  query FetchInitialMessages {
    assistantInitialMessages {
      data {
        id
        attributes {
          role
          content
          createdAt
        }
      }
    }
    assistantExistingThread {
      data {
        id
      }
    }
  }
`;

const FETCH_THREAD_MESSAGE = gql`
  query FetchThreadMessages($threadId: ID!) {
    messages (
      filters: {thread: {id: {eq: $threadId}}}
    ) {
      data {
        id
        attributes {
          role
          content
          createdAt
        }
      }
    }
  }
`;

const CREATE_THREAD = gql`
  mutation CreateThread {
    assistantCreateThread {
      data {
        id
      }
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($threadId: ID!, $data: CreateMessageInput!) {
    assistantCreateMessage(threadId: $threadId, data: $data)
  }
`;
