/* eslint-disable no-underscore-dangle */
import {
  Box,
  List,
  ListSubheader,
} from '@mui/material';

import PropTypes from 'prop-types';
import { useTenant } from '@lib/hooks';
import AuthAclGuard from './AuthAclGuard';
import MainSidebarItem from './MainSidebarItem';

const renderNavItems = ({
  depth = 0, items, path, isCollapsed,
}) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({
      acc,
      item,
      depth,
      path,
      isCollapsed,
    }), [])}
  </List>
);

const reduceChildRoutes = ({
  acc, item, depth, path, isCollapsed,
}) => {
  const key = `${item.title}-${depth}`;
  const partialMatch = path.includes(item.path);
  const exactMatch = path === item.path;
  const WrapperComponent = item.wrapperComponent ?? EmptyWrapper;

  if (item.children) {
    acc.push(
      <WrapperComponent key={key}>
        <AuthAclGuard requiredAcl={item.acl ?? []}>
          <MainSidebarItem
            active={partialMatch}
            chip={item.chip}
            depth={depth}
            icon={item.icon}
            info={item.info}
            isCollapsed={isCollapsed}
            module={item.module}
            open={partialMatch}
            path={item.path}
            title={item.title}
          >
            {renderNavItems({
              depth: depth + 1,
              items: item.children,
              path,
              isCollapsed,
            })}
          </MainSidebarItem>
        </AuthAclGuard>
      </WrapperComponent>,
    );
  } else {
    acc.push(
      <WrapperComponent key={key}>
        <AuthAclGuard requiredAcl={item.acl ?? []}>
          <MainSidebarItem
            active={exactMatch}
            chip={item.chip}
            depth={depth}
            icon={item.icon}
            info={item.info}
            isCollapsed={isCollapsed}
            module={item.module}
            path={item.path}
            title={item.title}
          />
        </AuthAclGuard>
      </WrapperComponent>,
    );
  }

  return acc;
};

export default function MainSidebarSection(props) {
  const {
    isCollapsed,
    items,
    path,
    tenant,
    title,
    ...other
  } = props;
  const { hasTenantModule } = useTenant();

  const tenantModules = items.filter((item) => hasTenantModule(item.type));

  return tenantModules.length > 0 && (
    <List
      subheader={isCollapsed ? (
        <Box mb={2} />
      ) : (
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: (theme) => theme.palette.sidebar?.subheader?.main ?? 'neutral.500',
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items: tenantModules,
        path,
        isCollapsed,
      })}
    </List>
  );
}

MainSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function EmptyWrapper({ children }) {
  return children;
}
