import {
  AppBar,
  Box,
  Drawer,
  Fab,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  ToggleButton,
  FormControlLabel,
  Switch,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useCallback,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
} from 'react-icons/tb';
import {
  FiMessageSquare,
} from 'react-icons/fi';
import Chat from '@components/Chat';
import { EXPANDED_SECONDARY_SIDEBAR_WIDTH } from '../theme';

const SecondarySidebarRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    gridArea: '1 / 3 / 3 / 4',
  },
}));

const AppBarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3],
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none',
    }),
}));

export default function SecondarySidebar(props) {
  const {
    isCollapsed,
    isCollapsible,
    onClose,
    onCollapse,
    onExpand,
    open,
  } = props;
  const [nativeChatUI, setNativeChatUI] = useState(false);

  const handleToggleSidebarCollapse = useCallback(() => {
    if (!isCollapsible) {
      if (open) {
        onClose?.();
      }

      return;
    }

    if (isCollapsed) {
      onExpand?.();
    } else {
      onCollapse?.();
    }
  }, [
    isCollapsed,
    isCollapsible,
    onClose,
    onCollapse,
    onExpand,
    open,
  ]);

  const content = (
    <SecondarySidebarRoot>
      <AppBarRoot
        position="static"
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <Tooltip
            placement="right"
            title="Einklappen"
          >
            <IconButton
              onClick={handleToggleSidebarCollapse}
            >
              {isCollapsed ? (
                <TbLayoutSidebarRightExpand />
              ) : (
                <TbLayoutSidebarRightCollapse />
              )}
            </IconButton>
          </Tooltip>
          {!isCollapsed && (
            <FormControlLabel
              color="textPrimary"
              control={(
                <Switch
                  checked={nativeChatUI}
                  onChange={(event) => setNativeChatUI(event.target.checked)}
                  size="small"
                />
              )}
              label="Beta UI"
              slotProps={{
                typography: {
                  variant: 'caption',
                  color: 'textPrimary',
                },
              }}
              sx={{ ml: 'auto' }}
            />
          )}
        </Toolbar>
      </AppBarRoot>
      {!isCollapsed ? (
        <>
          {nativeChatUI ? (
            <iframe
              frameborder="0"
              src="https://www.chatbase.co/chatbot-iframe/5aDIujNvuX05YNWWQtlak"
              style={{
                width: '100%',
                height: '100%',
                marginBottom: -40,
              }}
              title="chat"
              width="100%"
            />
          ) : (
            <Chat />
          )}
          <span />
        </>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          sx={{ height: '100%', py: 2 }}
        >
          <Tooltip
            placement="right"
            title="Chat öffnen"
          >
            <Fab
              color="primary"
              onClick={handleToggleSidebarCollapse}
              size="medium"
              sx={{ mt: 'auto' }}
            >
              <FiMessageSquare />
            </Fab>
          </Tooltip>
        </Box>
      )}
    </SecondarySidebarRoot>
  );

  if (isCollapsible) {
    return (
      <Paper
        square
        sx={{
          gridArea: '1 / 3 / 3 / 4',
          backgroundColor: 'background.paper',
          borderLeftColor: 'divider',
          borderLeftStyle: 'solid',
          borderLeftWidth: 1,
        }}
      >
        {content}
      </Paper>
    );
  }

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: EXPANDED_SECONDARY_SIDEBAR_WIDTH,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

SecondarySidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

SecondarySidebar.defaultProps = {
  onClose: null,
  open: false,
};
