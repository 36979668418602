import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { FiSend } from 'react-icons/fi';
import {
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export default function MessageTextBox(props) {
  const {
    disabled,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(DEFAULT_INPUT_VALUE);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    if (!value?.trim()) {
      return;
    }

    onSubmit(value.trim());
    setValue(DEFAULT_INPUT_VALUE);
  }, [value, onSubmit, setValue]);

  const handleChange = useCallback((event) => {
    setValue(event.target.value);
  }, [setValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.which === 13 && !event.shiftKey) {
      if (!event.repeat) {
        handleSubmit(event);
      }

      event.preventDefault(); // Prevents the addition of a new line in the text field
    }
  }, [handleSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex">
        <TextField
          disabled={disabled}
          fullWidth
          label={t('components.Chat.MessageBox.label')}
          maxLength={500}
          multiline
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          rows={Math.min(value.split('\n').length, 3)}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 0,
            },
          }}
          value={value}
          variant="outlined"
        />
        <Tooltip placement="left" title="Send message">
          <IconButton
            disabled={disabled || !value?.trim()}
            size="small"
            sx={{ ml: 1 }}
            type="submit"
          >
            <FiSend />
          </IconButton>
        </Tooltip>
      </Box>
    </form>
  );
}

const DEFAULT_INPUT_VALUE = '';
